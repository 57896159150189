import React, {Component} from "react";
import {
	Box,
	Grid,
	Button,
	TextField,
	Typography,
	FormHelperText,

	Backdrop,
	CircularProgress
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	Formik
} from "formik";
import {
	IMaskInput
} from "react-imask";
import {
	Notification,
	notificationTypes
} from "../../../common/Notification";
import * as Yup from "yup";
import agent from "../../../agent/agent";
import {Link} from "react-router-dom";

class Authorization extends Component {
	constructor(props) {
		super(props);

		this.state = {
			form: {
				phone: "",
				confirmationId: "",
				code: "",
			},

			step: 0,

			isShowBackdrop: false
		};

		this.refFormik = React.createRef();
	}

	componentDidMount = () => {}

	submit = async () => {
		const { step } = this.state;

		if (step === 0) {
			await this.submitCreateConfirmation();

			return
		}
		if (step === 1) {
			await this.submitConfirmationAccess();

			return
		}
	}
	submitCreateConfirmation = async () => {
		this.setState({ isShowBackdrop: true });

		const values = this.refFormik.current.values;
		const body = {
			"phone": `7${ values.phone }`
		};

		const response = await agent.post(`/auth/create-confirmation`, body).then((res) => {
			return res.data
		}).catch((err) => {
			return { error: err.response || true }
		})
		if (response.error) {
			this.setState({ isShowBackdrop: false });

			Notification({
				type: notificationTypes.error,
				message: response.error?.data?.details || "Ошибка сервера"
			});

			return
		}

		this.setState({
			step: 1,
			isShowBackdrop: false,
			form: {
				...this.state.form,
				confirmationId: response.confirmationId
			}
		});
	}
	submitConfirmationAccess = async () => {
		this.setState({ isShowBackdrop: true });

		const response = await agent.post(`/auth/confirmation-access`, {
			code: this.refFormik.current.values.code,
			confirmationId: this.state.form.confirmationId,
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return { error: err.response }
		})
		if (response.error) {
			this.setState({ isShowBackdrop: false });

			Notification({
				type: notificationTypes.error,
				message: response.error?.data?.details || "Ошибка сервера"
			})

			return null
		}

		agent.defaults.headers["x-auth-token"] = response.accessToken;
		localStorage.setItem('accessToken', response.accessToken);
		await this.props.setUser();
		this.setState({ isShowBackdrop: false });
	}

	changeForm = ({ target }) => {
		const { value, name } = target;

		let values = this.refFormik.current.values;
		values[name] = value;
		this.refFormik.current.setValues(values);
	}

	render() {
		const {
			classes
		} = this.props;
		const {
			form,
			step,
			isShowBackdrop
		} = this.state;

		return (
			<Box className={classes.root}>

				<Formik
					innerRef={this.refFormik}
					initialValues={form}
					validationSchema={Boolean(step === 0) ? validationSchema : validationSchemaAuth}
					onSubmit={this.submit}
				>{(props) => {
					const {
						values,
						errors,
						touched,
						handleSubmit
					} = props;

					return (
						<Grid
							container
							spacing={2}

						>

							<Grid item xs={12}>
								<Typography variant="h1" textAlign="center">Авторизация</Typography>
							</Grid>

							<Grid item xs={12}>
								<TextField
									value={values.phone}

									name="phone"
									variant="outlined"
									label="Номер телефона"
									color="secondary"
									size="small"
									disabled={Boolean(step === 1)}
									autoFocus

									fullWidth={true}
									error={Boolean(touched['phone'] && errors['phone'])}
									helperText={touched.phone && errors.phone}

									inputProps={{
										mask: "+7 (000) 000-00-00",
										onComplete: (event, { _unmaskedValue: value }) => this.changeForm({target: { value, name: "phone" }})
									}}
									InputProps={{
										inputComponent: IMaskInput
									}}
								/>
							</Grid>

							{Boolean(step === 1) && (
								<Grid item xs={12}>
									<TextField
										value={values.code}

										name="code"
										variant="outlined"
										label="Код авторизации"
										color="secondary"
										size="small"

										fullWidth={true}
										error={Boolean(touched['code'] && errors['code'])}
										helperText={touched.code && errors.code}

										onChange={this.changeForm}
									/>
									<FormHelperText>
										Последние 4 цифры номера телефона
									</FormHelperText>
								</Grid>
							)}

							<Grid item xs={12}>
								<Button fullWidth variant="contained" color="primary" onClick={handleSubmit}>
									{Boolean(step === 0) ? "Получить код" : "Авторизоваться"}
								</Button>
							</Grid>

						</Grid>
					)
				}}</Formik>

				<Box className={classes.authLogPass}>
					<Button component={Link} to="/authorization/log-pass">
						Вход для диагноста
					</Button>
				</Box>


				<Backdrop open={isShowBackdrop}>
					<CircularProgress/>
				</Backdrop>

			</Box>
		);
	}
}

const styles = {
	root: {
		maxWidth: 640,
		margin: "0 auto",
		position: "relative",

		padding: 20,
		boxSizing: "border-box",
		backgroundColor: "white",
		borderRadius: 14,
		borderBottomRightRadius: 0,
		border: "1px solid #F0F0F0",
	},
	authLogPass: {
		padding: 4,
		borderRadius: "0 0 8px 8px",
		border: "1px solid #F0F0F0",
		borderTop: "none",
		top: "100%", right: "-1px",
		position: "absolute",
		boxSizing: "border-box",
		backgroundColor: "white",

		"& .MuiButton-root": {
			fontSize: 14,
			lineHeight: 1.1,
			height: "auto",
			padding: "4px 12px",
		}
	}
}
Authorization = withStyles(styles)(Authorization)

const validationSchema = Yup.object().shape({
	phone: Yup.string().length(10, "Некорректно набранный номер").required('Заполните поле'),
});
const validationSchemaAuth = Yup.object().shape({
	code: Yup.string().required('Заполните поле'),
});

export default Authorization
